import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";

import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'

import AppRouter from "./AppRouter";

// import * as AMI from 'ami.js'
import useApp from "./useApp";

// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';

import "./App.css";
import {useSelector} from "react-redux";

// import helpersStack from "./libs/loaders/helpers.stack";


const darkTheme = createTheme({
	palette: {
		mode: "light"
		// mode: 'dark',
	},
});

const rollbarConfig = {
  accessToken: 'df92222b1f984b1bb2546eee958fb1a1',
  environment: process.env.REACT_APP_ENV_NAME,
	autoInstrument: false
};

function App() {
	
	const { checkCacheEnabled, setAppLang, setAppDateLocalization } = useApp();
	const {profile} = useSelector((state) => state.profile);
	
	//
	checkCacheEnabled();
	//
	setAppLang();
	//
	setAppDateLocalization();
	
	useEffect(()=>{},[profile]);
	
	return (
		<Provider config={rollbarConfig}>
      <ErrorBoundary>
				<ThemeProvider theme={darkTheme}>
					<div className="App">
						<AppRouter/>
					</div>
					<ToastContainer/>
				</ThemeProvider>
      </ErrorBoundary>
    </Provider>
		
	);
}

export default App;
