import React from "react";
import {useParams} from "react-router-dom";

import Typography from "@mui/material/Typography";
import UIModal from "@ui/UIModal/UIModal";
import Alert from "@mui/material/Alert";

import InstrDataCreateForm from "../CreateForm/InstrDataCreateForm";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const InstrDataCreateModal = ({isOpen, setIsOpen, initialOverride}) => {
	
	const {patientId} = useParams();
	const {modalTitle, modalAlert} = useTranslate({translates});
	
	return (
		<UIModal isOpen={isOpen} setIsOpen={setIsOpen}>
			<Typography variant="h6" component="h2" sx={{mb: 1}}>{modalTitle}</Typography>
			<Alert severity="warning">{modalAlert}</Alert>
			<InstrDataCreateForm patientId={patientId} initialOverride={initialOverride} setIsOpen={setIsOpen}/>
		</UIModal>
	);
};
export default InstrDataCreateModal;