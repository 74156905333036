import update from 'immutability-helper';
import {
	SCENE_CHILDREN_SET,
	SCENE_3D_CHILDREN_SET,
	SYNTH_CHILDREN_SET,
	ORIG_CHILDREN_SET,
	ACTIVE_INDEX_SET,
	PIAL_LEFT_SET,
	PIAL_RIGHT_SET,
	SYNTH_VISIBLE_SET,
	ORIG_VISIBLE_SET,
	USER_DATA_SET,
	GLTF_READY_SET,
	POINT_POS_SET,
	POINT_POS_AND_ACTIVE_INDEX_SET,
	CAMERA_NEAR_SET,
	SHOW_SLICES_ON_3D_SET,
	SHOW_SYNTH_SET,
	POINT_INFO_SET,
	ZOOM_SET,
	DIM_SET,
} from "./constants";


const initialGltfState = {
	sceneChildren: [],
	scene3DChildren: [],
	synthChildren: [],
	origChildren: [],
	activeIndex: { x:128, y:128, z:128 },
	pialLeft:null,
	pialRight:null,
	synthVisible:0,
	origVisible:0,
	userData:null,
	gltfReady:false,
	pointPos:{ x:0, y:0, z:0 },
	dims:['z','x','y'],
	zoom:{x:1,y:1,z:1 },
	camera_near: 10,
	showSlicesOn3D:false,
	showSynth:true,
	pointInfo:null,
};

export const gltf = (state = initialGltfState, action) => {
	switch (action.type) {
		
		case SCENE_CHILDREN_SET:
			return update(state,{ sceneChildren:{ $set:action.payload } });
			
		case SCENE_3D_CHILDREN_SET:
			return update(state,{ scene3DChildren:{ $set:action.payload } });
		
		case SYNTH_CHILDREN_SET:
			return update(state,{ synthChildren:{ $set:action.payload } });
			
		case ORIG_CHILDREN_SET:
			return update(state,{ origChildren:{ $set:action.payload } });
			
		case ACTIVE_INDEX_SET:
			return update(state,{ activeIndex:{ $set:action.payload } });
			
		case PIAL_LEFT_SET:
			return update(state,{ pialLeft:{ $set:action.payload } });
		
		case PIAL_RIGHT_SET:
			return update(state,{ pialRight:{ $set:action.payload } });
		
		case SYNTH_VISIBLE_SET:
			return update(state,{ synthVisible:{ $set:action.payload } });
			
		case ORIG_VISIBLE_SET:
			return update(state,{ origVisible:{ $set:action.payload } });
			
		case USER_DATA_SET:
			return update(state,{ userData:{ $set:action.payload } });
			
		case GLTF_READY_SET:
			return update(state,{ gltfReady:{ $set:action.payload } });
			
		case POINT_POS_SET:
			return update(state,{ pointPos:{ $set:action.payload } });
			
		case POINT_POS_AND_ACTIVE_INDEX_SET:
			return update(state,{
				pointPos:{ $set:action.payload.pointPos },
				activeIndex:{ $set:action.payload.activeIndex }
			});
		case CAMERA_NEAR_SET:
			return update(state,{camera_near:{$set:action.payload}});
			
		case SHOW_SLICES_ON_3D_SET:
			return update(state,{showSlicesOn3D:{$set:action.payload}});
			
		case SHOW_SYNTH_SET:
			return update(state,{showSynth:{$set:action.payload}});
			
		case POINT_INFO_SET:
			return update(state,{pointInfo:{$set:action.payload}});
			
		case ZOOM_SET:
			return update(state,{zoom:{$set:action.payload}});
		case DIM_SET:
			return update(state,{dims:{$set:action.payload}});
			
		default:
			return state;
	}
};
