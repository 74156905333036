import {useState} from "react";
import {useDispatch} from "react-redux";
// import { set } from 'idb-keyval';

import {fields, initialValues} from "./fields";
import {patientArchiveAddRedux} from "@redux/patient/actions";
// import {rawStore} from "../../../idbStore/init";
// import {unzipToArrayBuffer} from "@utils/index";
import rawDataCreateRequest from "@api/raw-data/create";


const useInstrDataCreateForm = ({patientId, setIsOpen}) => {
	
	const dispatch = useDispatch();
	const [progress, setProgress] = useState(null);
	
	const onSubmit = async (values, {setSubmitting, setStatus, setErrors}) => {
		
		const _values = { ...values, subject_id:patientId};
		
		// const blob = new Blob([_values.raw_data],{type: 'application/zip'});
		
		const {isSuccess, data, message, errors} = await rawDataCreateRequest( _values,setProgress);
		
		if (isSuccess) {
			// idb store caching
			// const files = await unzipToArrayBuffer(blob);
			dispatch(patientArchiveAddRedux(data));
			// set(data.id, files, rawStore);
			
			setIsOpen(false);
		} else {
			setStatus(message);
			// setTimeout(() => setStatus(""), 3000);
			errors && setErrors(errors.json);
		}
		
		setSubmitting(false);
	};
	return {
		progress,
		onSubmit,
		fields,
		initialValues,
	};
};
export default useInstrDataCreateForm;