import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {activeIndexSetRedux, pointPosSetRedux} from "@redux/gltf/actions";
import processingGetClustersRequest from "@api/processing/getClusters";


const useClustersList = () => {
	
	const dispatch = useDispatch();
	const {processingId} = useParams();
	
	const [clusters, setClusters] = useState([]);
	const { activeIndex } = useSelector((state) => state.gltf);
	const { pointPos } = useSelector((state) => state.gltf);
	
	const isItemActive = (slices)=> pointPos.x===(slices[0] - 128) && pointPos.y===(256 - slices[1] - 128) && pointPos.z===(slices[2] - 128);
	
	const onItemClick = async (slices) => {
		
		// console.log("???????????????????????????")
		// console.log(activeIndex)
		// console.log(slices)
		// console.log({x:slices[0]+(256/2),y:slices[1]+(256/2),z:slices[2]})
		// console.log("{x: 116, y: 184, z: 86}")
		// {x: 113, y: 186, z: 84}
		dispatch(activeIndexSetRedux({x:slices[0],y:(256 - slices[1]),z:slices[2]}));
		
		dispatch(pointPosSetRedux({x:slices[0] - 128,y:((256 - slices[1]) - 128),z:(slices[2] - 128)}))
	};
	
	const getClusters = async ()=>{
		try {
			const { isSuccess,data } = await processingGetClustersRequest(processingId);
			// console.log(errors)
			isSuccess && setClusters(data);
			
		} catch (e) {
			console.error(e)
		}
	};
	
	useEffect(()=>{
		getClusters()
	},[]);
	
	return {
		clustersList: clusters,
		onItemClick:onItemClick,
		isItemActive,
	};
};

export default useClustersList;