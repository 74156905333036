import React, {} from 'react';
import ClusterItem from "../ClusterItem";

import useClustersList from "./useClustersList";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const ClustersList = () => {
	
	const {clustersList, onItemClick, isItemActive} = useClustersList();
	
	return (
		<div>
			
			{clustersList.map((cluster,i)=>(
				<div key={i}>
					<br></br>
					<div>{cluster.name}</div>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 65 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>slices</TableCell>
									<TableCell align="right">chance</TableCell>
									<TableCell align="right">size</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{cluster.points.map((point) => (
									<TableRow
										hover
										// key={point.name}
										sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderLeft:(isItemActive(point.slices) ? "2px inset green" : undefined) }}
										onClick={()=>onItemClick(point.slices)}
									>
										<TableCell component="th" scope="row" >
											{point.slices.join(",")}
										</TableCell>
										<TableCell align="right">{point.chance}</TableCell>
										<TableCell align="right">{point.size}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					
					{/*<div>
						{cluster.points.map((point,j)=>(
							<ClusterItem point={point} onClick={onItemClick} key={j}/>
						))}
					</div>*/}
				</div>
			))}
		</div>
	);
};
export default ClustersList;