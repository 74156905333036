import React, {} from "react";

import Typography from "@mui/material/Typography";


const ErrorState = () => {
	return (
		<Typography sx={{ mt: 2, color:"red" }}>error</Typography>
	);
};
export default ErrorState;
