export default [
	"вс",
	"пн",
	"вт",
	"ср",
	"чт",
	"пт",
	"сб",
	"воскресенье",
	"понедельник",
	"вторник",
	"среда",
	"четверг",
	"пятница",
	"суббота",
];