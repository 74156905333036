import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import GLTFCanvas from "../GLTFCanvas/GLTFCanvas";
import CanvasVolume from "../CanvasVolume/CanvasVolume";
import GLTFCanvasWrapper from "../GLTFCanvasWrapper/GLTFCanvasWrapper";

import LoadingState from "./components/LoadingState";
import ErrorState from "./components/ErrorState";

import useGLTFCanvasGrid from "./useGLTFCanvasGrid";

// import dims from "./dims";

import GLTFCanvasGridStyle from "./GLTFCanvasGrid.style"
import {dimSetRedux} from "@redux/gltf/actions";
const dimsSettings = {
	x:{
		name: "x",
		label:"saggital",
		camera:{
			position: [300, 0, 0], up: [0, 1, 0],
		}
	},
	y:{
		name: "y",
		label:"axial",
		camera:{
			position: [0, 300, 0], up: [0, 0, 1],
		}
	},
	z:{
		name: "z",
		label:"coronal",
		camera:{
			position: [0, 0, 300],
		},
	}
};

const GLTFCanvasGrid = ({_archive}) => {
	
	const dispatch = useDispatch();
	const {loadGltf, clearReduxFromGltf} = useGLTFCanvasGrid();
	const [loading, setLoading] = useState(true);
	
	const { scene3DChildren, origVisible, synthChildren,origChildren, synthVisible, gltfReady, zoom, dims } = useSelector((state) => state.gltf);
	
	useEffect(() => {
		loadGltf(_archive)
			.then(()=>{
				/*setTimeout(()=>{
					dispatch(dimSetRedux(['z','z','z']))
				},5000)
				setTimeout(()=>{
					dispatch(dimSetRedux(['x','y','z']))
				},10000)*/
			})
			.catch(error=>{
				console.log(error);
				setLoading(false)
			});
		
		return ()=>{
			clearReduxFromGltf();
		}
	}, []);
	useEffect(() => {}, [dims]);
	
	return (
		<>
			{!gltfReady
				? (
					<Stack alignItems={'center'} justifyContent={'center'} sx={{height: "100vh", width:'100%'}}>
						{ loading
							? <LoadingState/>
							: <ErrorState/>
						}
					</Stack>)
				: (
					<Box sx={GLTFCanvasGridStyle}>
						
						{ scene3DChildren.length !==0
							? <CanvasVolume archive={_archive}/>
							: <Box/>
						}
						
						{dims.map((dim, i)=>
							<GLTFCanvasWrapper dim={dimsSettings[dim]} key={i}>
								<GLTFCanvas
									node={origChildren[1][origChildren[0].indexOf("MyNode_orig_"+dimsSettings[dim].name)][origVisible]}
									synth={synthChildren[1][synthChildren[0].indexOf("MyNode_synth_"+dimsSettings[dim].name)][synthVisible]}
									dim={dimsSettings[dim]}
								/>
							</GLTFCanvasWrapper>
						)}
					
					</Box>
				)
			}
		</>
	);
};
export default GLTFCanvasGrid;
