import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import dateFormat from "dateformat";

import {processingByDataIdDeleteRedux, processingByDataIdUpdateRedux} from "@redux/processing/actions";
import {patientDataSetRedux} from "@redux/patient/actions";
import processingDeleteRequest from "@api/processing/delete";
import patientGetDataRequest from "@api/patient/getData";
import {PROCESSING_STATES, PROCESSINGS_ROOT} from "@api/constants";
import {buildUrl} from "@api/settings";
import getStatusColor from "./getStatusColor";


const dateMask = "d mmmm, HH:MM";

const useProcessingItem = ({processing}) => {
	
	const navigate = useNavigate();
	const {patientId} = useParams();
	const dispatch = useDispatch();
	
	const {id, raw_data_id, create_date, end_date, synthetic_data, status} = processing;
	
	const formattedStartDate = `${dateFormat(create_date, dateMask)}`;
	const formattedEndDate = end_date !==null && `${dateFormat(end_date, dateMask)}`;
	
	const isProcessEnded = (status) => status !== PROCESSING_STATES.progress && status !== PROCESSING_STATES.untouched && status !== PROCESSING_STATES.convert;
	const statusColor = getStatusColor(status);
	
	const handleButtonDeleteClick = async () => {
		await processingDeleteRequest(id);
		dispatch(processingByDataIdDeleteRedux({dataId:raw_data_id, processingId:id}))
	};
	
	const handleEyeClick = ()=>{
		navigate(`/patients/${patientId}/inspection/${synthetic_data.id}/processing/${synthetic_data.processing_id}/gltf/`)
	};
	
	const handleSSEMessage = useCallback(async (newProcessing) => {
		const {id, raw_data_id, status} = newProcessing;
		dispatch(processingByDataIdUpdateRedux({dataId:raw_data_id, processingId:id, data:newProcessing}));
		
		if (isProcessEnded(status)){
			const result = await patientGetDataRequest(patientId);
			result.isSuccess && dispatch(patientDataSetRedux(result.data))
		}
	},[dispatch,patientId]);
	
	useEffect(()=>{
		if (!isProcessEnded(status)){
			let eventSource = new EventSource(buildUrl(`/${PROCESSINGS_ROOT}/${id}/sse`));
			
			eventSource.onmessage = async (event) => {
				const parsedData = JSON.parse(event.data);
				await handleSSEMessage(parsedData);
				parsedData.status === PROCESSING_STATES.finished || parsedData.status === PROCESSING_STATES.failed && eventSource.close();
			};
			return (()=>{
				eventSource.close();
			})
		}
	},[handleSSEMessage]);
	
	return {
		handleButtonDeleteClick,
		handleEyeClick,
		formattedStartDate,
		formattedEndDate,
		statusColor,
	};
};
export default useProcessingItem;