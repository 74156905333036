import {Lut} from "three/examples/jsm/math/Lut";
import {parseFloat32EndianSwappedArray, parseUInt32EndianSwapped} from "@utils/index";


const createColorPairs = (colorsBufferData)=>{
	
	const decoder = new TextDecoder("x-user-defined");
	const data = decoder.decode(new Uint8Array(colorsBufferData));
	
	const nvertices = parseUInt32EndianSwapped(data, 3); // 134121
	const al_ret = parseFloat32EndianSwappedArray(data, 15, nvertices); // Array(134121)
	
	let colorsPars = [];
	
	let colors = [];
	let lut = (new Lut('test', al_ret[0].length)).setMax(1);
	
	al_ret[0].forEach((color) => {
		colors.push(lut.getColor(color));
	});
	
	colors.forEach((color) => {
		colorsPars.push(color.r, color.g, color.b);
	});
	
	return colorsPars;
};

export default createColorPairs;