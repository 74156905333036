import React, {} from 'react';

import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import RunProcessButton from "@components/RunProcessButton/RunProcessButton";
import ProcessingList from "../ProcessingList/ProcessingList";

import useDataAccordion from "./useDataAccordion";


const DataAccordion = ({dataItem}) => {
	
	const { handleDeleteClick } = useDataAccordion({dataItem});
	
	return (
		<Accordion expanded disableGutters>
			<AccordionSummary
				expandIcon={<IconButton edge="end" aria-label="delete" sx={{transform:'rotate(180deg)'}} onClick={handleDeleteClick}>
					<DeleteIcon />
				</IconButton>}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
			>
				<Typography sx={{ width: '33%', flexShrink: 0 }}>{`data ${dataItem.name}`}</Typography>
				{/*<Typography sx={{ color: 'text.secondary' }}>extra text</Typography>*/}
				<RunProcessButton dataId={dataItem.id}/>
			</AccordionSummary>
			<AccordionDetails>
				Расчеты
				<ProcessingList dataId={dataItem.id}/>
			</AccordionDetails>
		</Accordion>
	);
};

export default DataAccordion;