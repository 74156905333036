import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Float32BufferAttribute} from "three";
import {GLTFLoader} from "three-stdlib";

import {pialLeftSetRedux, pialRightSetRedux} from "@redux/gltf/actions";
import createColorPairs from "./createColorPairs";


const useCanvasVolume = (archive) => {
	
	const dispatch = useDispatch();
	
	const [loading, setLoading] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [error, setError] = useState(false);
	
	const loadGltfVolume = async () => {
		
		setLoading(true);
		console.log(archive)
		const filesKeys = Object.keys(archive.files);
		
		const pialKeys = filesKeys.filter(item=>item.includes("pial"));
		
		if (pialKeys.length === 0){
			setEmpty(true);
			setLoading(false);
			return;
		}
		const pialLeftName = pialKeys.find(item=>item.includes("lh"));
		const pialRightName = pialKeys.find(item=>item.includes("rh"));
		
		const pialLeftObj = await archive.file(pialLeftName).async("ArrayBuffer");
		const pialRightObj = await archive.file(pialRightName).async("ArrayBuffer");
		
		const gltfLoader = new GLTFLoader();
		
		const gltfLeft = await gltfLoader.parseAsync(pialLeftObj, "");
		const gltfRight = await gltfLoader.parseAsync(pialRightObj, "");
		
		const geometryLeft = gltfLeft.scene.children[0].geometry;
		const geometryRight = gltfRight.scene.children[0].geometry;
		
		// userData.test - temporary place for color buffers
		if (gltfLeft.userData.test && gltfRight.userData.test){
			
			gltfLeft.userData.test.forEach((colorsBuffer, i) => {
				const colorsParsLeft = createColorPairs(new Uint8Array(colorsBuffer.data));
				if (i === 0) {
					geometryLeft.setAttribute(`color`, new Float32BufferAttribute(colorsParsLeft, 3));
				}
				geometryLeft.setAttribute(`color_${i + 1}`, new Float32BufferAttribute(colorsParsLeft, 3));
			});
			
			gltfRight.userData.test.forEach((colorsBuffer, i) => {
				const colorsParsRight = createColorPairs(new Uint8Array(colorsBuffer.data));
				if (i === 0) {
					geometryRight.setAttribute('color', new Float32BufferAttribute(colorsParsRight, 3));
				}
				geometryRight.setAttribute(`color_${i + 1}`, new Float32BufferAttribute(colorsParsRight, 3));
			});
			
		}
		const _gLeftM = gltfLeft.scene.children[0].material;
		const _gRightM = gltfRight.scene.children[0].material;
		
		_gLeftM.vertexColors = true;
		_gRightM.vertexColors = true;
		
		_gLeftM.transparent = true;
		_gRightM.transparent = true;
		
		dispatch(pialLeftSetRedux(gltfLeft));
		dispatch(pialRightSetRedux(gltfRight));
		setLoading(false);
	};
	
	const clearGltfVolume = async () => {
		dispatch(pialLeftSetRedux(null));
		dispatch(pialRightSetRedux(null));
	};
	
	useEffect(()=>{
		
		loadGltfVolume(archive)
			.catch((e)=>{
				console.error(e);
				setError(true);
				setLoading(false);
			});
		
		return ()=>{
			
			clearGltfVolume()
				.catch((e)=>console.error(e))
		}
	},[]);
	
	return {
		loadGltfVolume,
		clearGltfVolume,
		loading,
		empty,
		error,
		setLoading,
	}
	
};

export default useCanvasVolume;