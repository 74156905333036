import React, { useEffect, useRef, useState} from 'react';

import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

import useProcessingItem from "./useProcessingItem";

import {PROCESSING_PERCENTS, PROCESSING_STATES_LABELS} from "@api/constants";
import millisToMinutesAndSeconds from "./millisToMinutesAndSeconds";


const ProcessingItem = ({processing}) => {
	
	const intervalRef = useRef(null);
	
	const {status, modality, data_hash, synthetic_data, create_date, end_date } = processing;
	const { handleButtonDeleteClick, formattedStartDate, formattedEndDate, handleEyeClick, statusColor } = useProcessingItem({processing});
	const [timer, setTimer] = useState(0);
	
	useEffect(() => {

		let diff;
		
		if (status === "finished" || status === "failed"){
			diff = (new Date(end_date)).getTime() - (new Date(create_date)).getTime();
		} else {
			diff = (new Date()).getTime() - (new Date(create_date)).getTime();
		}
		setTimer(diff);
		
		if (status !== "finished" && status !== "failed") {
			
			const intervalId = setInterval(() => {
				setTimer((prevTimer) => prevTimer + 1000)
			}, 1000)
			intervalRef.current = intervalId;
			
		}
		
		return () => {
			const intervalId = intervalRef.current;
			clearInterval(intervalId)
		};
	}, []);
	
	useEffect(() => {
		if (status === "finished" || status === "failed") {
			const intervalId = intervalRef.current;
			clearInterval(intervalId)
		}
	}, [status]);

	return (
		<ListItem
			secondaryAction={
				<Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
					{synthetic_data !== null && status !== "failed" && (
						<IconButton edge="end" aria-label="delete" onClick={handleEyeClick}>
							<VisibilityIcon color={statusColor} />
						</IconButton>
					)}
					<IconButton edge="end" aria-label="delete" onClick={handleButtonDeleteClick}>
						<DeleteIcon />
					</IconButton>
				</Stack>
			}
		>
			<ListItemText primary={
					<Stack direction={'row'} justifyContent={'space-between'} >
						<Box sx={{display:'inline-block'}}>
							id: {processing.id} <Chip label={modality} size={'small'} /> Hash: {data_hash} <Chip label={PROCESSING_STATES_LABELS[status]} color={statusColor} size={'small'}/>
						</Box>
					</Stack>
				} secondary={
					<>
						<Typography variant="caption" display="block" gutterBottom>{`старт: ${formattedStartDate}`}</Typography>
						{end_date && (
							<>
								<Typography variant="caption" display="block" gutterBottom>{`финиш: ${formattedEndDate}`}</Typography>
								<Typography variant="caption" display="block" gutterBottom>{`длительность: ${millisToMinutesAndSeconds(timer)}`}</Typography>
							</>
						)}
						{status !== "untouched" && (
							<LinearProgress variant="determinate" color={statusColor} value={PROCESSING_PERCENTS[status]} />
						)}
						
					</>
				}
			/>
		</ListItem>
	);
};
export default ProcessingItem;