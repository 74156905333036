const buildResponse = (result) => {
	if (!result.data){
		return { isSuccess:false, message:result };
	}
	const content = result.data;
	if (result.status === 200 || result.status === 201 ){
		return { isSuccess:true, data:content };
	}
	return content.errors
		? { isSuccess:false, errors:content.errors, message:`${content.code} ${content.status}`, code:content.code, status:content.status }
		: { isSuccess:false, message:content.message, code:content.code, status:content.status };
};
export default buildResponse;