import {GLTFLoader} from "three-stdlib";
import {useDispatch, useSelector} from "react-redux";

import {
	gltfReadySetRedux, origChildrenSetRedux,
	scene3DChildrenSetRedux,
	sceneChildrenSetRedux,
	synthChildrenSetRedux,
	userDataSetRedux
} from "@redux/gltf/actions";


const useGLTFCanvasGrid = () => {
	
	const dispatch = useDispatch();
	
	const {
		sceneChildren,
		scene3DChildren,
		synthChildren,
	} = useSelector((state) => state.gltf);
	
	const loadGltf = async (archive) => {
		console.log(archive.files);
		// const patt1=/\.glb+$/i;
		// console.log(archive)
		// console.log(archive.files)
		// console.log(Object.keys(archive.files)[0])
		const fileName = Object.keys(archive.files).find(item=>!item.includes("pial"));
		// const pathReplacer = new RegExp(".glb");
		// const gltfFileName = "stack-single-half.glb";
		
		const glbObj = await archive.file(fileName).async("ArrayBuffer");
		console.log(glbObj);
		const gltfLoader = new GLTFLoader();
		
		const g = await gltfLoader.parseAsync(glbObj, "");
		console.log(g);
		
		// console.log(g.parser.json.sourceCache[0])
		dispatch(userDataSetRedux(g.userData));
		// const synthLength = g.userData.synthNames.length;
		const origDims = g.scene.children.filter(child=>child.name.includes("orig"));
		
		const origCount = origDims.length/3;
		
		const arr = Array.from(Array(origCount).keys()).map(item=>""+item);
		
		let _sceneChildren;
		let _scene3DChildren = [];
		let _synthChildren = [[],[],g.userData.synthNames || []];
		let _origChildren = [[],[],g.userData.origNames || arr];
		

		g.scene.children.forEach(item=>{
			if (item.userData.type === "orig"){
				const newItem = item.clone();
				
				if (newItem.isGroup){
					_scene3DChildren.push(newItem.children[0]);
				} else {
					_scene3DChildren.push(newItem);
				}
			}
		});
		
		//
		_sceneChildren = g.scene.children.map(item=>{
			const newItem = item.clone();
			console.log(newItem);
			newItem.parent = null;
			if (newItem.userData.type === "synth"){
				
				if (newItem.isGroup){
					_synthChildren[0].push(newItem.name);
					_synthChildren[1].push(newItem.children.map((child)=> {
						child.parent = null;
						return child.clone()
					}));
					newItem.children = [];
				} else {
					_synthChildren[0].push(newItem.name);
					_synthChildren[1].push([newItem]);
				}
				
			} else if (newItem.userData.type === "orig"){
				if (newItem.isGroup){
					_origChildren[0].push(newItem.name);
					_origChildren[1].push(newItem.children.map((child)=> {
						child.parent = null;
						return child.clone()
					}));
					newItem.children = [];
				} else {
					_origChildren[0].push(newItem.name);
					_origChildren[1].push([newItem]);
				}
			}
			
			// item.material.wireframe = true;
			
			// item.parent = null;
			// item.children = [];
			
			return item;
		});
		
		_synthChildren[1].forEach((group)=>{
			group.forEach((mesh, i)=>{
				mesh.visible = i === 0;
			})
		});
		
		dispatch(synthChildrenSetRedux(_synthChildren));
		dispatch(origChildrenSetRedux(_origChildren));
		
		dispatch(sceneChildrenSetRedux(_sceneChildren));
		dispatch(scene3DChildrenSetRedux(_scene3DChildren));
		
		dispatch(gltfReadySetRedux(true));
		
	};
	
	const clearReduxFromGltf = ()=>{
		dispatch(synthChildrenSetRedux([]));
		dispatch(origChildrenSetRedux([]));
		
		dispatch(sceneChildrenSetRedux([]));
		dispatch(scene3DChildrenSetRedux([]));
		
		dispatch(gltfReadySetRedux(false));
	};
	
	return {
		loadGltf,
		sceneChildren,
		scene3DChildren,
		synthChildren,
		clearReduxFromGltf,
	};
};
export default useGLTFCanvasGrid;
