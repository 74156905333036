import React, {} from "react";

import axiosService from "../../libs/services/axiosService";
import JSZip from "jszip";
import roiGetRequest from "@api/roi/get";
import {roiPointActiveSetRedux} from "@redux/ROIPoint/actions";
import {editableProtocolSetRedux} from "@redux/protocol/actions";
import {activeIndexSetRedux, pointInfoSetRedux, pointPosSetRedux} from "@redux/gltf/actions";
import roiGetCommentsRequest from "@api/roi/getComments";
import {commentsSetRedux} from "@redux/comment/actions";
import processingGetPointInfoRequest from "@api/processing/getPointInfo";
import {useDispatch} from "react-redux";
import {DIMS_NUM} from "./../../constants";
import {useSearchParams} from "react-router-dom";
import syntheticDataGetRequest from "@api/synthetic-data/get";
import {buildUrl} from "@api/settings";

const getPointIndexByCoords = (xi,yi,zi)=> zi*(DIMS_NUM*DIMS_NUM) + yi*DIMS_NUM + xi;

const useGLTFViewerPage = () => {
	
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	
	const controller = new AbortController();
	
	const [archive, setArchive] = React.useState(null);
	const [percent, setPercent] = React.useState(0);
	const [message, setMessage] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	
	const getArchive = async (url)=>{
		
		setIsLoading(true);
		setMessage("archive loading");
		
		const response = await axiosService.get(url, {
			signal:controller.signal,
			responseType:'arraybuffer',
			onDownloadProgress: (progressEvent) => {
				// console.log(progressEvent.progress);
				setPercent(progressEvent.progress);
			}
		});

		setMessage("archive unpacking");
		
		const zip = new JSZip();
		const archive = await zip.loadAsync(response.data);
		
		setMessage("");
		setArchive(archive);
		setIsLoading(false);
		
		return archive;
	};
	
	const getSyntheticData = async (dataId)=>{
		
		const {isSuccess,data} = await syntheticDataGetRequest(dataId);
		
		if (!isSuccess) return;
		
		console.log(data);
		console.log(data.path.replace("_all","_gltf"));
		// const zipUrl = buildUrl('/uploads/raw_data/stack-single-full.zip');
		// const zipUrl = '/__data/stack-single-full.zip';
		// const zipUrl = '/__data/stack-single-half.zip';
		// const zipUrl = '/__data/30-single-full.zip';
		// const zipUrl = buildUrl("/synthetic_data/6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b/30_all_gltf.zip");
		// const zipUrl = '/__data/30_all_gltf.zip';
		// const zipUrl = '/__data/marinets.zip';
		
		
		const getGltfPath = ()=>{
			return data.path
				.replace("_all","_gltf")
				.replace("/api","")
		};
		const zipUrl = buildUrl(getGltfPath());
		
		// http://10.16.88.53:3002/api/synthetic_data/7a61b53701befdae0eeeffaecc73f14e20b537bb0f8b91ad7c2936dc63562b25/30.zip
		try {
			const result = await getArchive(zipUrl)
			console.log(result)
		} catch (e) {
			console.error(e)
		}
		
	};
	
	const getProtocolsAndPoints = async()=>{
		
		const protocolId = searchParams.get("protocol");
		const pointId = searchParams.get("point");
		
		const resp = await roiGetRequest(pointId);
		
		dispatch(roiPointActiveSetRedux(parseInt(resp.data.id)));
		const coords = JSON.parse(resp.data.note.split(';').at(0));
		
		dispatch(editableProtocolSetRedux({protocolId:parseInt(protocolId)}));
		dispatch(activeIndexSetRedux({
			x:Math.ceil(coords.x) + (256/2),
			y:Math.ceil(coords.y) + (256/2),
			z:Math.ceil(coords.z) + (256/2),
		}));
		
		dispatch(pointPosSetRedux({
			x: Math.ceil(coords.x),
			y: Math.ceil(coords.y),
			z: Math.ceil(coords.z)
		}));
		
		const {data} = await roiGetCommentsRequest(resp.data.id);
		dispatch(commentsSetRedux(data))
		
		const _pointX = Math.ceil(coords.x);
		const _pointY = Math.ceil(coords.y);
		const _pointZ = Math.ceil(coords.z);
		const pointIndex = getPointIndexByCoords(_pointX + (256/2), _pointY + (256/2), _pointZ + (256/2));
		try {
			const {data} = await processingGetPointInfoRequest(1,pointIndex);
			
			dispatch(pointInfoSetRedux(data));
			
		} catch (e) {
			console.error(e)
		}
		
	}
	
	return {
		getArchive,
		archive,
		percent,
		message,
		isLoading,
		controller,
		getProtocolsAndPoints,
		getSyntheticData,
	};
};
export default useGLTFViewerPage;