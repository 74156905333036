export default [
	"янв",
	"февр",
	"март",
	"апр",
	"май",
	"июнь",
	"июль",
	"авг",
	"сент",
	"окт",
	"нояб",
	"дек",
	"январь",
	"февраль",
	"март",
	"апрель",
	"май",
	"июнь",
	"июль",
	"август",
	"сентябрь",
	"октябрь",
	"ноябрь",
	"декабрь",
];