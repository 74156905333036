import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import CircularProgressWithLabel from "@ui/CircularProgressWithLabel";
import InspectionLayout from "@ui/layout/InspectionLayout";

import ROIPointGltfList from "@ROIPoint/GltfList/ROIPointGltfList";
import InspectionProtocolStateRow from "../PatientSingle/Inspection/components/ProtocolStateRow/ProtocolStateRow";
import InspectionProtocolList from "../PatientSingle/Inspection/components/ProtocolList/ProtocolList";
import CommentsBlock from "../PatientSingle/Protocols/SingleProtocol/components/CommentsBlock/CommentsBlock";

import LevaControls from "./components/LevaControls/LevaControls";
import ClustersList from "./components/ClustersList/ClustersList";
import GltfRoiPointListHead from "./components/CanvasVolume/components/ROIPointListHead/ROIPointListHead";
import GLTFCanvasGrid from "./components/GLTFCanvasGrid/GLTFCanvasGrid";

import useGLTFViewerPage from "./useGLTFViewer";
import useTranslate from "@hooks/useTranslate";

import translates from "../PatientSingle/Inspection/translates";


const GLTFViewerPage = () => {
	
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const {dataId} = useParams();
	
	const {protocolsTitle, backButtonTitle} = useTranslate({translates});
	
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	const {archive, percent, message, isLoading, controller, getProtocolsAndPoints, getSyntheticData} = useGLTFViewerPage();
	
	const { gltfReady, pointInfo } = useSelector((state) => state.gltf);
	
	const handleBackButtonClick = () => navigate(-1);
	const handleBackButton2Click = () => navigate("./../");
	
	
	useEffect(() => {
		getSyntheticData(dataId)
			.catch(error=>console.log(error));
		
		const protocolId = searchParams.get("protocol");
		const pointId = searchParams.get("point");
		
		if (protocolId && pointId){
			getProtocolsAndPoints()
				.catch(error=>console.log(error));
		}
		return (()=>{
			controller.abort();
		})
	}, []);

	return (
		<>
			{isLoading || archive===null ? (
				<Stack alignItems={'center'} justifyContent={'center'} sx={{position: "relative", height: '100vh'}}>
					<Stack alignItems={'center'}>
						<CircularProgressWithLabel value={percent*100} />
						{isLoading && <div style={{marginTop:"10px"}}>{message}</div>}
					</Stack>
				</Stack>
			):(
				<InspectionLayout>{{
					main: (
						<>
							<Box sx={{mt: 2, ml:2, position:'absolute', zIndex:10}}>
								<Button variant='contained' size='small' onClick={handleBackButtonClick} sx={{}}>{backButtonTitle}</Button>
								<Button variant='contained' size='small' onClick={handleBackButton2Click} sx={{ml:2}}>old visualizer</Button>
							</Box>
							<GLTFCanvasGrid _archive={archive}/>
							{gltfReady && <LevaControls/>}
						</>
					),
					aside: (
						<Box sx={{p: 2, boxSizing: "border-box"}}>
							{gltfReady && (
								<>
									{pointInfo !== null && (
										<>
											<Typography component='h3' variant='h6'>Информация о точке</Typography>
											{ Object.entries(pointInfo).map( ([key,value])=>
												<div key={key} style={{color:(key !== "index" &&  parseInt(value)>95)?"red":"default"}}>{key} : {value}</div>
											)}
											<Divider sx={{my: 2}}/>
										</>
									)}
									<ClustersList/>
									<Stack sx={{height: 300, width:'100%'}}>
										{/*<InspectionRoiPointListHead isArchiveLoading={false}/>*/}
										<GltfRoiPointListHead isArchiveLoading={false}/>
										<ROIPointGltfList sx={{width: '100%', bgcolor: 'background.paper', mb: 1}}/>
									</Stack>
									<InspectionProtocolStateRow/>
									<Divider sx={{my: 2}}/>
									<Typography component='h3' variant='h6'>{protocolsTitle}</Typography>
									
									<InspectionProtocolList/>
									<Divider sx={{my: 2}}/>
									{roiPointActive !==null && (<CommentsBlock/>)}
								</>
							)}
						</Box>
					)
				}}
				</InspectionLayout>
			)}
		</>
	);
};
export default GLTFViewerPage;
