import React, {} from "react";

import CircularProgress from "@mui/material/CircularProgress";


const LoadingState = () => {
	return (
		<>
			<CircularProgress size={40} sx={{ mb:2 }} />
			подготовка изображения...
		</>
	);
};
export default LoadingState;
