const translates = {
	en: {
		modalTitle: 'Add instrumental data',
		modalAlert: 'Archive must includes 3 directories with modalities: fl t1 and t2',
	},
	ru: {
		modalTitle: 'Добавить инструментальные исследования',
		modalAlert: 'архив должен содержать 3 директории с модальностями: fl t1 и t2',
	}
};
export default translates;