import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {activeIndexSetRedux, pointInfoSetRedux, pointPosSetRedux, zoomSetRedux} from "@redux/gltf/actions";

import {DIMS_NUM} from "./../../../../constants";

import processingGetPointInfoRequest from "@api/processing/getPointInfo";


const useGltfCanvas = ({ node, synth, dim }) => {
	
	const {processingId} = useParams();
	const dispatch = useDispatch();
	
	const { activeIndex, pointPos, zoom } = useSelector((state) => state.gltf);
	
	const [enableZoom, setEnableZoom] = React.useState(false);
	// console.log(pointPos)
	const updateDrawRange = (nodeDim)=>{
		
		const count = Math.round( 6 );
		
		nodeDim.geometry.setDrawRange(Math.round( activeIndex[dim.name]*6 ), count );
		
		if (synth !==null){
			synth.geometry.setDrawRange(Math.round( activeIndex[dim.name]*6 ), count );
		}
		
	};
	const changeSlice = (e)=>{
		// const dim = node.userData.dim;
		
		const delta = e.deltaY || e.detail || e.wheelDelta;
		
		const newIndex = (delta < 0) ? activeIndex[dim.name] -1 : activeIndex[dim.name]+1;
		
		dispatch(activeIndexSetRedux({...activeIndex, [dim.name]:newIndex }));
		dispatch(pointPosSetRedux({ ...pointPos, [dim.name]:newIndex - (256/2) }));
		
		updateDrawRange(node);
	};
	const changeZoom = (e)=>{
		if (e.shiftKey) {
			const step = 0.2;
			if (e.deltaY < 0) {
				zoom[dim.name] < 10
				&& dispatch(zoomSetRedux({
						...zoom,
						[dim.name]:(parseFloat((zoom[dim.name] + step).toFixed(1)))
					}
				))
				
			} else {
				zoom[dim.name] > 1
					&& dispatch(zoomSetRedux({
						...zoom,
						[dim.name]:(parseFloat((zoom[dim.name] - step).toFixed(1)))
					}))
			}
		}
	};
	
	const onWheel = (e)=>{
		e.shiftKey ? changeZoom(e) : changeSlice(e);
	};
	
	const getPointIndexByCoords = (xi,yi,zi)=> xi*(DIMS_NUM*DIMS_NUM) + yi*DIMS_NUM + zi;
	
	const click = async (e)=>{
		
		if (e.delta > 5){
			return;
		}
		const _pointX = Math.ceil(e.point.x);
		const _pointY = Math.ceil(e.point.y);
		const _pointZ = Math.ceil(e.point.z);
		
		dispatch(activeIndexSetRedux({ x:_pointX + (256/2), y:_pointY + (256/2), z:_pointZ + (256/2) }));
		
		const bla = await dispatch(pointPosSetRedux({ x: _pointX, y: _pointY, z: _pointZ }));
		console.log({...bla.payload})
		const pointIndex = getPointIndexByCoords((bla.payload.x + 128), (256 - bla.payload.y - 128), (bla.payload.z + 128));
		console.log({x:(bla.payload.x + 128),y:(256 - bla.payload.y - 128),z:(bla.payload.z + 128)})
		console.log(pointIndex)
		try {
			const {data} = await processingGetPointInfoRequest(processingId,pointIndex);
			
			dispatch(pointInfoSetRedux(data));
			
		} catch (e) {
			console.error(e)
		}
		
	};
	
	const handleKeyDown = e => {
		if(e.key === "Shift") setEnableZoom(true)
	};
	
	const handleKeyUp = e => {
		if(e.key === "Shift") setEnableZoom(false)
	};
	
	return {
		enableZoom,
		click,
		onWheel,
		updateDrawRange,
		handleKeyDown,
		handleKeyUp,
	};
};

export default useGltfCanvas;