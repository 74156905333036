import React, {Suspense, useEffect, useRef, useState} from "react";
import {Canvas, useThree} from "@react-three/fiber";
import {useSelector} from "react-redux";
import {MapControls, Stats} from "@react-three/drei";
// import {OrbitControls} from "@react-three/drei";

import * as THREE from 'three'
import Pointer from "../Pointer";

import useGltfCanvas from "./useGLTFCanvas";
import RoiPoints from "./components/RoiPoints";
import MainAxes from "@components/MainAxes";
import {Camera} from "three/src/cameras/Camera";
import {PerspectiveCamera} from "three/src/cameras/PerspectiveCamera";


const GLTFCanvas = ({ node, synth, dim }) => {
	
	const groupRef = useRef();
	
	const [camera, setCamera] = useState(new THREE.OrthographicCamera());
	const [n, setN] = useState(node.clone());
	const [s, setS] = useState(synth.clone());
	
	const { onWheel, updateDrawRange, click, handleKeyDown, handleKeyUp} = useGltfCanvas({ node, synth, dim });
	const { activeIndex, pointPos, showSynth, zoom, dims } = useSelector((state) => state.gltf);

	// to show synth tomograms OVER the orig tomograms
	const getSynthPosition = ()=>{
		return [
			dim.name === 'x' ? 0.2 : 0,
			dim.name === 'y' ? 0.2 : 0,
			dim.name === 'z' ? 0.2 : 0
		];
	};
	
	const helperPosition = [
			dim.name === 'x' ? pointPos.x + 0.3 : pointPos.x,
			dim.name === 'y' ? pointPos.y + 0.3 : pointPos.y,
			dim.name === 'z' ? pointPos.z + 0.3 : pointPos.z
		];
	
	useEffect(() => {
		
		// setCamera(new Camera().copy({...dim.camera, zoom:zoom[dim.name]}))
		// camera.updateMatrixWorld();
		// console.log(pointPos)
		// console.log(activeIndex)
		updateDrawRange(n);
	}, [pointPos, activeIndex]);
	
	useEffect(() => {
		console.log("!!!!!!!!!!!!!!!!!!!!!!!")
		console.log(dim)
		console.log(zoom)
		// const cam = new THREE.OrthographicCamera()
		
		camera.zoom = zoom[dim.name]
		camera.position.set(...dim.camera.position)
		console.log(camera.position)
		if (dim.camera.up){
			camera.lookAt(...dim.camera.up)
		} else {
			camera.lookAt(...[0,0,0])
		}
		
		
		console.log(camera.lookAt)
		console.log(camera)
		
		
		camera.updateMatrixWorld();
		// setCamera(cam)
		
		
		setN(node.clone());
		setS(synth.clone())
		
		updateDrawRange(n);
	}, [ node, synth,  dim]);
	
	useEffect(() => {
		console.log(dim);
		console.log(node)
		
		setN(node.clone());
		setS(synth.clone())
		
		updateDrawRange(n);
		
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.addEventListener('keyup', handleKeyUp);
		}
	}, []);
	
	return (
		<Canvas camera={{...dim.camera, zoom:zoom[dim.name]}} onWheel={onWheel} style={{backgroundColor: "#000000"}}>
			<Suspense fallback={null}>
				<group ref={groupRef} dispose={<div>loading...</div>}>
					<primitive object={n} onClick={click} />
					{s !==null && showSynth && <primitive object={s} position={getSynthPosition()}/>}
				</group>
				
				<RoiPoints/>
				
				<Pointer pointPos={pointPos} dim={dim}/>
				{/*<Text color="red" anchorX="center" anchorY="middle" fontSize={56} >{node.userData.dim}</Text>*/}
				{/*<OrbitControls enableZoom={false} enableRotate={true}/>*/}
				<MapControls screenSpacePanning={true} enableDamping={false} enableRotate={false} enableZoom={false}/>
				<Stats />
				
				<MainAxes/>
				<axesHelper args={[256/2]} position={Object.values(helperPosition)} />
				<ambientLight/>
				
			</Suspense>
		</Canvas>
	);
};
export default GLTFCanvas;
