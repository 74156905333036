import axios from 'axios';
import {buildUrl} from "@api/settings";


class AxiosService {
  constructor() {
    const instance = axios.create();
    
    instance.defaults.headers['Content-Type'] = 'application/json';
    
    instance.interceptors.response.use(this.handleSuccess, this.handleError.bind(this));
    
    instance.interceptors.request.use(
      (config) => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    this.instance = instance;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    const originalConfig = error.config;
    const refreshToken = sessionStorage.getItem('refreshToken');
    
    if (
      originalConfig.url !== '/token/' &&
      error.response &&
      error.response.status === 401 &&
      !originalConfig._retry &&
      refreshToken
    ) {
      originalConfig._retry = true;

      // try {
        // this.instance
         return axios
          .post(buildUrl('/refresh'), {},{headers:{Authorization:`Bearer ${refreshToken}`}})
          .then((res) => {
            if (res.status === 200) {
              sessionStorage.setItem('accessToken', res.data.access_token);
              originalConfig.headers.Authorization = `Bearer ${res.data.access_token}`;
              return axios(originalConfig);
            }
          })
          .catch((err) => {
            console.error(err)
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
            window.location.replace("login");
          });
      /*} catch (_error) {
        console.log('try error')
        console.log(_error)
        return Promise.reject((_error.response && _error.response.data) || 'Something went wrong');
      }*/
    } else {
      console.error('error');
      console.error(error);
      return (error.response && {...error.response}) || 'Something went wrong';
    }
  }

  get(url, config) {
    return this.instance.get(url, config);
  }

  post(url, body, config) {
    return this.instance.post(url, body, config);
  }

  put(url, body, config) {
    return this.instance.put(url, body, config);
  }

  delete(url, config) {
    return this.instance.delete(url, config);
  }
}
export default new AxiosService();
