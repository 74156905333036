const getStatusColor = (status)=>{
	let color;
	switch (status) {
		case "untouched":
			color = "primary";
			break;
		case "finished":
			color = "success";
			break;
		case "failed":
			color = "error";
			break;
		case "converter":
			color = "warning";
			break;
		default:
			color = "primary";
			break;
	}
	return color;
};
export default getStatusColor;