import React, {Suspense, useRef} from 'react';
import {useSelector} from "react-redux";

import {Canvas} from "@react-three/fiber";
import {Stats, CameraControls} from "@react-three/drei";
// import {OrbitControls} from "@react-three/drei";

import Pointer from "../Pointer";

import Lights from "./components/Lights";
import Slices3D from "./components/Slices3D";
import Volume3D from "./components/Volume3D/Volume3D";
import MainAxes from "@components/MainAxes";
import useCanvasVolume from "./useCanvasVolume";
import Box from "@mui/material/Box";


const CanvasVolume = ({archive}) => {
	
	const cameraControlsRef = useRef();
	
	const {empty, loading, error} = useCanvasVolume(archive);
	
	const { pointPos, camera_near, showSlicesOn3D } = useSelector((state) => state.gltf);
	
	return (
		<>
			{(!loading && !empty && !error) ? (
				<Canvas camera={{position: [100, 100, 100], near:camera_near}} style={{backgroundColor: "#000000"}}>
					<Suspense fallback={null}>
						
						<Volume3D/>
						{false && showSlicesOn3D && <Slices3D/>}
						
						<Pointer pointPos={pointPos}/>
						
						<MainAxes/>
						<axesHelper args={[256/2]} position={Object.values(pointPos)} />
						
						{/*<OrbitControls dampingFactor={0.05}/>*/}
						<CameraControls
							ref={cameraControlsRef}
						/>
						<Stats />
						
						<Lights/>
					</Suspense>
				</Canvas>
			) : (
				<Box style={{display:"flex", alignItems:"center", justifyContent:"center", backgroundColor: "#000000", color:"#ffffff"}}>
					{loading && (<div>loading...</div>)}
					{empty && (<div>нет файлов для отображения</div>)}
					{error && (<div>ошибка отображения файлов</div>)}
				</Box>
			)}
			
		</>
	);
};

export default CanvasVolume;