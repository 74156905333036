const millisToMinutesAndSeconds = (millis) => {
	// 1- Convert to seconds:
	let seconds = (millis / 1000).toFixed(0);
	
	
	let days = parseInt( seconds / 86400 );
	seconds = seconds % 86400;
	
	// 2- Extract hours:
	let hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
	seconds = seconds % 3600; // seconds remaining after extracting hours
	// 3- Extract minutes:
	let minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
	// 4- Keep only seconds not extracted to minutes:
	seconds = seconds % 60;
	return days ? (days + "дн ") : "" +hours+"ч "+minutes+"м "+seconds + "c";
};
export default millisToMinutesAndSeconds;
