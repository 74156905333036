export const SCENE_CHILDREN_SET = 'SCENE_CHILDREN_SET';
export const SCENE_3D_CHILDREN_SET = 'SCENE_3D_CHILDREN_SET';
export const SYNTH_CHILDREN_SET = 'SYNTH_CHILDREN_SET';
export const ORIG_CHILDREN_SET = 'ORIG_CHILDREN_SET';

export const ACTIVE_INDEX_SET = 'ACTIVE_INDEX_SET';

export const PIAL_LEFT_SET = 'PIAL_LEFT_SET';
export const PIAL_RIGHT_SET = 'PIAL_RIGHT_SET';

export const SYNTH_VISIBLE_SET = 'SYNTH_VISIBLE_SET';
export const ORIG_VISIBLE_SET = 'ORIG_VISIBLE_SET';

export const USER_DATA_SET = 'USER_DATA_SET';

export const GLTF_READY_SET = 'GLTF_READY_SET';

export const POINT_POS_SET = 'POINT_POS_SET';

export const POINT_POS_AND_ACTIVE_INDEX_SET = 'POINT_POS_AND_ACTIVE_INDEX_SET';
export const CAMERA_NEAR_SET = 'CAMERA_NEAR_SET';
export const SHOW_SLICES_ON_3D_SET = 'SHOW_SLICES_ON_3D_SET';
export const SHOW_SYNTH_SET = 'SHOW_SYNTH_SET';
export const POINT_INFO_SET = 'POINT_INFO_SET';
export const ZOOM_SET = 'ZOOM_SET';
export const DIM_SET = 'DIM_SET';